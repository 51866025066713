import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Breadcrumb} from "../model/breadcrumb.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
 private history: Breadcrumb[] = [];
  private currentRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  private activatedRoute$: BehaviorSubject<ActivatedRoute> =
    new BehaviorSubject<ActivatedRoute>({} as ActivatedRoute);

  private _breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let child = router.routerState.root;
        while (child?.firstChild) {
          if (child.firstChild) {
            child = child.firstChild;
          } else {
            break;
          }
        }

        if (child) {
          const breadcrumb =
            child.routeConfig?.path === '' && child.parent
              ? this.getBreadcrumbFromRoute(child.parent)
              : this.getBreadcrumbFromRoute(child);

          if (
            this.history.length === 0 ||
            breadcrumb.route !== this.history[this.history.length - 1].route
          ) {
            this.history.push(breadcrumb);
          }
        }

        this.currentRoute$.next(event.url);
      }
    });
  }

  public get currentRoute(): Observable<string> {
    return this.currentRoute$.asObservable();
  }

  public get currentActivatedRoute(): Observable<ActivatedRoute> {
    return this.activatedRoute$.asObservable();
  }

  public getCurrentRouteValue(): string {
    return this.currentRoute$.value;
  }

  public getCurrentActivatedRouteValue(): ActivatedRoute {
    return this.activatedRoute$.value;
  }

  public getCurrentRouteSegments(): string[] {
    return this.currentRoute$.value.split('/');
  }

  public removePreviousRoute(): void {
    if (this.history.length === 1) {
      this.history.pop();
    } else if (this.history.length > 1) {
      this.history.splice(-2, 2);
    }
  }

  public getPreviousRouteInfo(): Breadcrumb | null {
    //const num = this.history.length;
    let info: Breadcrumb | null = null;
    if (this.history.length > 1) {
      info = this.history[this.history.length - 2];
    }
    return info;
  }

  public getRouteHistory(): Breadcrumb[] {
    return this.history;
  }

  /***
   * Breadcrumbs
   */
  getBreadcrumbFromRoute(route: ActivatedRoute): Breadcrumb {
    const parentLabel = route.parent?.snapshot.data['breadcrumbLabel'] ?? '';

    const label =
      route.snapshot.data['breadcrumbLabel'] &&
      route.snapshot.data['breadcrumbLabel'] !== parentLabel
        ? route.snapshot.data['breadcrumbLabel']
        : route.snapshot.url.map((entry) => entry.path).join(' :: ');

    const url = route.pathFromRoot.reduce((acc: string[], entry) => {
      if (
        entry.routeConfig &&
        entry.routeConfig.path &&
        entry.routeConfig.path !== ''
      ) {
        acc.push(entry.snapshot.url.map((entry) => entry.path).join('/'));
      }
      return acc;
    }, []);

    return {
      label: label,
      route: url.join('/'),
    };
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this._breadcrumbs = breadcrumbs;
  }

  getBreadcrumbs(): Breadcrumb[] {
    return this._breadcrumbs;
  }

  getCurrentURL(): string {
    return window.location.href;
  }
}
