export const environment = {
  production: false,
  BASE_API_URL: 'https://if.cyberdevelopment.house/',
  API_SPACES: {
    LIVE: 'live',
    MANAGEMENT: 'management',
    API: 'api',
  },
  ASSETS_API_URL: 'https://sa.cyberdevelopment.house/api',
  LIVE_API_KEY: 'live-api-key',
  LIVE_API_URL: 'https://if.cyberdevelopment.house/live',
  CONTENT_API_KEY:
    'fb8bd0c02c1df0d4250334b92bcae2df81e8c30e90cfdcb6190df7cee4e8d76217a84cc643800856213ff42a81d13a77d7b6738e5e4c51c8fb431e89a0c04cb764ba1c641b38ee5815780f0192f67de6d34ada68ee2bbdb5ff3aaa8b0853eebb6338d3f20cadbef27bd1719672af1bb97e79c5d45b98cc2efde6945d43c35a03',
  CONTENT_API_URL: 'https://strapi.cyberdevelopment.house/api',
  CONTENT_URL: 'https://strapi.cyberdevelopment.house',
  EXPORT_API_URL: 'https://export.cyberdevelopment.house',
  ENV: 'development',
  AUTH_REALM: 'CIH',
  AUTH_SERVER_URL: 'https://auth.cyberdevelopment.house/auth/',
  AUTH_RESOURCE: 'dashboard-dev',
  AUTH_CLIENT_ID: 'dashboard-dev',

  ANALYTICS: {
    enabled: true,
    url: 'https://analytics.cyberintelligence.house/',
    id: '2',
  },
};
