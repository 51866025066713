import { Injectable } from '@angular/core';
import {from, Observable, of} from "rxjs";
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {AuthEvents} from "../model/auth-events.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 KC_TOKEN_VALIDITY = 5; // Time to expiration to check token validity

  logoutTimer = -1;
  logoutTimerDelay = 0;
  userRoles: string[] = [];

  _userID = '';
  _userModules: string[] = [];

  keycloak: any; // eslint-disable-line

  SYSTEM_ROLES = [
    'manage-account',
    'manage-account-links',
    'view-profile',
    'offline_access',
    'uma_authorization',
    'default-roles-cih',
  ];

  constructor(private keycloakService: KeycloakService) {
    //console.log('Auth Service constructor');

    /***
     Initialize keycloak adapter instance
     The direct use of the adapter allows for better performance. The current angular wrapper does unnecessary
     calls to update token.
     LINK: https://github.com/mauriciovigolo/keycloak-angular/blob/master/projects/keycloak-angular/src/lib/core/services/keycloak.service.ts
     ***/
    this.keycloak = this.keycloakService.getKeycloakInstance();

    from(keycloakService.keycloakEvents$).subscribe((event) => {
      // Handle keycloak events.
      switch (event.type) {
        case AuthEvents.ON_TOKEN_EXPIRED:
          this.logoutTimer = setTimeout(() => {
            this.logout();
          }, this.logoutTimerDelay);
          break;

        case AuthEvents.ON_AUTH_REFRESH_SUCCESS: //Token refreshed successfully reset expiration timer
          this._clearTimeout();
          break;

        case AuthEvents.ON_AUTH_REFRESH_ERROR:
          this.logout();
          break;
      }
    });

    this.isLoggedIn().subscribe((result: boolean) => {
      if (result) {
        //console.log('isLoggedIn:', this.keycloak);
        //set the logout timer
        const { refreshTokenParsed, tokenParsed, subject } = this.keycloak;

        this._userModules = this.extractUserModules(tokenParsed.modules);

        this._userID = subject;

        this.logoutTimerDelay =
          (refreshTokenParsed.exp -
            refreshTokenParsed.iat -
            (tokenParsed.exp - tokenParsed.iat)) *
          1000;

        // get user roles - resource and realm
        this.userRoles = this.sanitizeRoles(
          this.keycloakService.getUserRoles()
        );
      }
    });
  }

  private sanitizeRoles(roles: string[]): string[] {
    const sanitizedRoles: string[] = [];
    for (const role of roles) {
      if (!this.SYSTEM_ROLES.includes(role)) {
        sanitizedRoles.push(role);
      }
    }

    return sanitizedRoles;
  }

  public isLoggedIn(): Observable<boolean> {
    return of(this.keycloakService.isLoggedIn());
  }

  public isAuthenticated(): boolean {
    return this.keycloak.authenticated ?? false;
  }

  public login(): void {
    this.keycloakService.login({ prompt: 'login' });
  }

  public logout(redirectURI?: string | undefined): void {
    this._clearTimeout();
    this._clearRoles();
    this._userModules = [];
    this._userID = '';
    this.keycloakService.logout(redirectURI);
  }

  public getToken(): string {
    return this.keycloak.token;
  }

  public isTokenValid(): boolean {
    return !this.keycloakService.isTokenExpired(this.KC_TOKEN_VALIDITY);
  }

  public getUpdatedToken(): string {
    const updateToken = async () => {
      await this.keycloakService.updateToken(/*this.KC_TOKEN_VALIDITY*/);
    };

    if (!this.isTokenValid()) {
      updateToken();
    }

    return this.getToken();
  }

  public hasRole(roles: string | string[]): boolean {
    if (typeof roles === 'string') {
      return this.userRoles.includes(roles);
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  public get userID(): string {
    return this._userID;
  }

  public getUserProfile(): Observable<KeycloakProfile> {
    return from(this.keycloakService.loadUserProfile());
  }

  public hasAccessToModule(module: string): boolean {
    return this._userModules.includes(module);
  }

  private _clearRoles(): void {
    this.userRoles = [];
  }

  private _clearTimeout(): void {
    if (this.logoutTimer !== -1) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = -1;
    }
  }

  private extractUserModules(attribute: string[]): string[] {
    const modules: string[] = [];

    if (attribute !== undefined) {
      for (const entry of attribute) {
        modules.push(...entry.split(','));
      }
    }

    return modules;
  }
}
