import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, Observable, of, shareReplay, switchMap} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class IconService {

  private _icons: Map<string, BehaviorSubject<string>> = new Map();

  constructor(private httpClient: HttpClient) {}

  getIconAsString(icon: string): Observable<string> {
    if (this._icons.has(icon)) {
      return this._icons.get(icon)?.asObservable() ?? of('');
    }

    const iconData = new BehaviorSubject<string>('');
    this._icons.set(icon, iconData);

    return this.httpClient
      .get(`./assets/icons/${icon}`, { responseType: 'text' })
      .pipe(
        switchMap((data: string) => {
          iconData.next(data);
          return iconData.asObservable();
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError((error: HttpErrorResponse) => {
          return of('');
        }),
        shareReplay()
      );
  }
}
