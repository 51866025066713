import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {emptyFunction} from "../../../utils/object";
import {IconService} from "../../../services/icon.service";
import {MatIconModule} from "@angular/material/icon";


@Component({
  selector: 'cep-icon',
  standalone: true,
  imports: [
    MatIconModule
],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent implements OnInit {
  @Input() icon = '';

  @Input() inheritColor = false;
  @Input() inline = false;

  isMaterialIcon = false;
  isOutlined = false;

  svgElement?: SafeHtml;
  subscription: Subscription = new Subscription();

  constructor(
    private iconService: IconService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    if (this.icon !== null && this.icon !== 'null') {
      if (this.icon.includes('.svg')) {
        this.subscription = this.iconService
          .getIconAsString(this.icon)
          .subscribe(
            (svg: string) => {
              this.svgElement = this.domSanitizer.bypassSecurityTrustHtml(svg);
            },
            emptyFunction,
            () => {
              if (this.subscription) {
                this.subscription.unsubscribe();
              }
            }
          );
      } else {
        this.isMaterialIcon = true;
        if (this.icon.includes('|outlined')) {
          this.icon = this.icon.replace('|outlined', '');
          this.isOutlined = true;
        }
      }
    }
  }
}
