export interface User {
  id?: string;
  username: string;
  first_name: string;
  last_name: string;
  name: string;
  roles: UserRole[];
  email: string;
  settings: unknown | null;
  enabled: boolean;
}


export const UserRoles = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  USER: 'user',
  ACCOUNT_MANAGER: 'accountManager',
  RISK_MANAGER: 'riskManager',
  CEI_MANAGER: 'ceiManager',
  MANAGER: 'manager',
  BUYER: 'buyer'
};

export const UserTypeRoles = [
  UserRoles.ACCOUNT_MANAGER,
  UserRoles.MANAGER,
  UserRoles.RISK_MANAGER,
  UserRoles.CEI_MANAGER,
  UserRoles.SUPER_ADMIN,
];

export type UserRole =
  | typeof UserRoles.ADMIN
  | typeof UserRoles.SUPER_ADMIN
  | typeof UserRoles.USER
  | typeof UserRoles.BUYER
  | typeof UserRoles.RISK_MANAGER
  | typeof UserRoles.ACCOUNT_MANAGER
  | typeof UserRoles.CEI_MANAGER
  | typeof UserRoles.MANAGER;
